import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { AppStore } from './store/AppStore'
import { configure } from 'mobx'
import { Provider } from 'mobx-react'

configure({
  enforceActions: 'always'
})

document.title = 'Diabetes Assessment Tool'

ReactDOM.render(<Provider AppStore={AppStore}>
  <App/>
</Provider>, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
