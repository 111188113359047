import React, { Component } from 'react'
import footer from './assets/result-footer.png'
import axios from 'axios'
import { backend_url } from './helpers'
import { observer, inject } from 'mobx-react'
import logoTest from './assets/logo-test.png'
import aba from './assets/aba.png'
import circle from './assets/circle.png'
import Gauge from 'react-svg-gauge'

@observer
@inject('AppStore')
class ResultPage extends Component {

  static getHexColor (value) {
    let string = value.toString(16)
    return (string.length === 1) ? '0' + string : string
  }

  render () {

    let r = Math.floor((this.props.score * 10) * 2.55)
    let g = Math.floor(255 - (this.props.score * 10 * 2.55))
    let b = 0
    let colorHex = '#' + ResultPage.getHexColor(r) + ResultPage.getHexColor(g) + ResultPage.getHexColor(b)

    // if (!this.props.AppStore.user.api_token) {
    //   return <div className="error left fixed-width para gray">
    //     Something went wrong, Please try again.
    //   </div>
    // }

    return <div>
      <div className="header">
        <div className={'header-wrap'}>
          <div><img className={'top-circle'} src={circle} alt=""/></div>
          <div><img className={'top-logo'} src={logoTest} alt=""/></div>
          <div><a target={'_blank'} href="http://www.diabetes.org"><img className={'aba-logo'} src={aba} alt=""/></a>
          </div>
        </div>
      </div>
      <div className="top-border"></div>
      <div className={'left fixed-width'}>
        <h2 className={'sixty-sec'}>our 60 second type 2 diabetes risk test</h2>
        <div className={'score'}>
          <div className={'left '}>
            <span>You are at</span>
            <h3>{this.renderRisk()} Risk</h3>
          </div>
          <div className="right">
            {/* 250 --> 200 */}
            <Gauge color={colorHex} value={this.props.score} max={10} min={0} width={300} height={240} label=""
                   valueLabelStyle={{fontSize: 40}} valueFormatter={value => {
              return `${value}/10`
            }}/>
          </div>


        </div>
        <p className={'risk-type second-heading'}>Right now, your risk for having type 2
          diabetes is {this.renderRisk()}</p>
        <p className={'para gray'}>However, your risk changes over the time. Be sure to schedule
          regular check-ups with your doctor and take
          healthy steps toward preventing or
          delaying diabetes</p>
      </div>


      <div className={'share'}>
        <div className={'fb-share-button'} data-href={'https://getz.clients.thebrandcrew.com/'} data-layout="button"
             data-size="small" data-mobile-iframe="true"><a target="_blank"
                                                            href={'https://www.facebook.com/sharer/sharer.php?u=https://getz.clients.thebrandcrew.com/'}
                                                            className={'fb-xfbml-parse-ignore'}>Share</a></div>

      </div>

      <div className="footer">
        <img src={footer} alt=""/>
      </div>
    </div>
  }

  renderRisk () {
    if (this.props.score >= 5) {
      return <span className="risk risk-high">High</span>
    }

    return <span className="risk risk-low">Low</span>
  }

  componentDidMount () {
    // send data to server
    if (this.props.AppStore.user.api_token) {
      axios.post(backend_url('/tests'), {
        api_token: this.props.AppStore.user.api_token,
        score: this.props.score,
        answers: this.props.answers
      }).then(() => {
        // completed
      }).catch(error => {
        console.warn(error)
      })
    }

    console.log('ResultPage.js', this.props.AppStore.user)
  }
}

export default ResultPage
