import React, { Component } from 'react'
import './App.css'

import * as Survey from 'survey-react'
import 'survey-react/survey.css'
import circle from './assets/circle.png'
import SmallCircle from './assets/small-circle.png'
import StartPage from './StartPage'
import ResultPage from './ResultPage'
import { Calculator } from './Calculator'
import { inject, observer } from 'mobx-react'
import { backend_url } from './helpers'
import logoTest from './assets/logo-test.png'
import aba from './assets/aba.png'

@inject('AppStore')
@observer
class App extends Component {

  questions = {
    title: 'our 60 second type 2 diabetes risk test',
    pages: [
      {
        name: 'page1',
        elements: [
          {
            type: 'radiogroup',
            name: 'test_for',
            title: 'Are you taking this test for yourself, or a loved one?',
            isRequired: true,
            choices: [
              {
                value: 'myself',
                text: 'For MySelf'
              },
              {
                value: 'someone',
                text: 'For Someone Else'
              }
            ]
          }
        ]
      },
      {
        name: 'page2',
        elements: [
          {
            type: 'radiogroup',
            name: 'age',
            title: 'How old are you?',
            isRequired: true,
            choices: [
              {
                value: 'less40',
                text: 'Less than 40 years'
              },
              {
                value: '40-49',
                text: '40 - 49 years'
              },
              {
                value: '50-59',
                text: '50 - 59 years'
              },
              {
                value: 'above60',
                text: '60 years or older'
              }
            ]
          }
        ]
      },
      {
        name: 'page3',
        elements: [
          {
            type: 'radiogroup',
            name: 'gender',
            title: 'What is your gender?',
            isRequired: true,
            choices: [
              {
                value: 'man',
                text: 'Man'
              },
              {
                value: 'woman',
                text: 'Woman'
              }
            ]
          },
          {
            type: 'radiogroup',
            name: 'is_diagnosed',
            visibleIf: '{gender} = "woman"',
            title: 'Have you ever been diagnosed with gestational diabetes?',
            isRequired: true,
            choices: [
              {
                value: 'yes',
                text: 'Yes'
              },
              {
                value: 'no',
                text: 'No'
              }
            ]
          }
        ]
      },
      {
        name: 'page5',
        elements: [
          {
            type: 'radiogroup',
            name: 'is_genetic',
            title: 'Do you have a mother, father, sister or brother with diabetes?',
            isRequired: true,
            choices: [
              {
                value: 'yes',
                text: 'Yes'
              },
              {
                value: 'no',
                text: 'No'
              }
            ]
          }
        ]
      },
      {
        name: 'page6',
        elements: [
          {
            type: 'radiogroup',
            name: 'has_blood_pressure',
            title: 'Have you ever been diagnosed with high blood pressure?',
            isRequired: true,
            choices: [
              {
                value: 'yes',
                text: 'Yes'
              },
              {
                value: 'no',
                text: 'No'
              }
            ]
          }
        ]
      },
      {
        name: 'page7',
        elements: [
          {
            type: 'radiogroup',
            name: 'is_active',
            title: 'Are you physically active?',
            isRequired: true,
            choices: [
              {
                value: 'yes',
                text: 'Yes'
              },
              {
                value: 'no',
                text: 'No'
              }
            ]
          }
        ]
      },
      {
        name: 'page8',
        elements: [
          {
            type: 'dropdown',
            name: 'race',
            title: 'What race or ethnicity best describes you?',
            isRequired: true,
            choices: [
              {
                value: 'white',
                text: 'White'
              },
              {
                value: 'Pakistani',
                text: 'Pakistani'
              },
              {
                value: 'hispanic-latino',
                text: 'Hispanic and/or Latino'
              },
              {
                value: 'american-indian-or-alaska-native',
                text: 'American Indian or Alaska Native'
              },
              {
                value: 'black-or-african-american',
                text: 'Black or African American'
              },
              {
                value: 'native-hawaiian-or-other-pacific-islander',
                text: 'Native Hawaiian or Other Pacific Islander'
              },
              {
                value: 'not-to-say',
                text: 'Prefer Not To Say'
              },
              {
                value: 'other',
                text: 'Other'
              }
            ]
          }
        ]
      },
      {
        name: 'page9',
        elements: [
          {
            type: 'panel',
            name: 'panel1',
            elements: [
              {
                type: 'html',
                name: 'About yourself',
                html: 'Tell us more about yourself.'
              },
              {
                type: 'dropdown',
                name: 'height',
                title: 'Height',
                isRequired: true,
                choices: [
                  {
                    value: '147cm',
                    text: '4\' 10" (147cm)'
                  },
                  {
                    value: '150cm',
                    text: '4\' 11" (150cm)'
                  },
                  {
                    value: '152cm',
                    text: '5\' 0" (152cm)'
                  },
                  {
                    value: '155cm',
                    text: '5\' 1" (155cm)'
                  },
                  {
                    value: '157cm',
                    text: '5\' 2" (157cm)'
                  },
                  {
                    value: '160cm',
                    text: '5\' 3" (160cm)'
                  },
                  {
                    value: '163cm',
                    text: '5\' 4" (163cm)'
                  },
                  {
                    value: '165cm',
                    text: '5\' 5" (165cm)'
                  },
                  {
                    value: '168cm',
                    text: '5\' 6" (168cm)'
                  },
                  {
                    value: '170cm',
                    text: '5\' 7" (170cm)'
                  },
                  {
                    value: '173cm',
                    text: '5\' 8" (173cm)'
                  },
                  {
                    value: '175cm',
                    text: '5\' 9" (175cm)'
                  },
                  {
                    value: '178cm',
                    text: '5\' 10" (178cm)'
                  },
                  {
                    value: '180cm',
                    text: '5\' 11" (180cm)'
                  },
                  {
                    value: '183cm',
                    text: '6\' 0" (183cm)'
                  },
                  {
                    value: '185cm',
                    text: '6\' 1" (185cm)'
                  },
                  {
                    value: '188cm',
                    text: '6\' 2" (188cm)'
                  },
                  {
                    value: '191cm',
                    text: '6\' 3" (191cm)'
                  },
                  {
                    value: '193cm',
                    text: '6\' 4" (193cm)'
                  }
                ]
              },
              {
                type: 'dropdown',
                name: 'weight',
                isRequired: true,
                choicesByUrl: {
                  url: backend_url('/choices/{height}'),
                  valueName: 'key',
                  titleName: 'text'
                }
              }
            ]
          }
        ]
      }
    ],
    showQuestionNumbers: 'off',
    showProgressBar: 'top',
    goNextPageAutomatic: true,
    startSurveyText: 'Start'
  }

  constructor (props) {
    super(props)
    this.state = {
      isStarted: false,
      isCompleted: false,
      score: 0,
      answers: [],
    }
  }

  render () {

    let appClasses = 'App'

    if (window.top === window.self) {
      appClasses += ' web-app'
    } else {
      appClasses += ' facebook-app'
    }

    if (!this.state.isStarted) {
      appClasses += ' start-page'
    } else if (this.state.isCompleted) {
      appClasses += ' result-page'
    }

    return (
      <div className={appClasses}>
        {this.renderPage()}
      </div>
    )
  }

  renderPage () {

    if (!this.state.isStarted) {
      return <StartPage onStart={this.onStart.bind(this)}/>
    } else if (this.state.isCompleted) {
      return <ResultPage score={this.state.score} answers={this.state.answers}/>
    }

    // if (!this.props.AppStore.user.api_token) {
    //   return <div className="error left fixed-width para gray">
    //     Something went wrong, Please try again.
    //   </div>
    // }

    let model = new Survey.Model(this.questions)

    return <div>
      <div className="header">
        <div className={'header-wrap'}>
          <div><img className={'top-circle'} src={circle} alt=""/></div>
          <div><img className={'top-logo'} src={logoTest} alt=""/></div>
          <div><a target={'_blank'} href="http://www.diabetes.org"><img className={'aba-logo'} src={aba} alt=""/></a>
          </div>
        </div>
      </div>
      <div className="top-border"></div>
      <Survey.Survey
        pagePrevText={' '}
        pageNextText={' '}
        model={model}
        onComplete={this.onComplete}
      />
      <div className={'circleBottom'}>
        <img src={SmallCircle} alt=""/>
      </div>
    </div>

  }

  onComplete = model => {
    window._ = model

    let score = (new Calculator(model.getAllValues())).calculate()

    this.setState({
      isCompleted: true,
      score,
      answers: model.getAllValues()
    })
  }

  onStart () {
    this.setState({
      isStarted: true,
    })

  }

}

export default App
