export class Calculator {
  static points = {
    age: {
      less40: 0,
      '40-49': 1,
      '50-59': 2,
      'above60': 3,
    },
    gender: {
      man: 1,
      woman: 0,
    },
    is_diagnosed: {
      yes: 1,
      no: 0,
    },
    is_genetic: {
      yes: 1,
      no: 0,
    },
    has_blood_pressure: {
      yes: 1,
      no: 0,
    },
    is_active: {
      yes: 0,
      no: 1,
    },
    race: {
      white: 0,
      asian: 0,
      Pakistani: 0,
      'hispanic-latino': 1,
      'american-indian-or-alaska-native': 1,
      'black-or-african-american': 1,
      'native-hawaiian-or-other-pacific-islander': 1,
      'not-to-say': 0,
      other: 0,
    },
    height_weight: (height, weight) => {
      console.log(height, weight)
      let [weight_start, weight_end] = weight.split('-').map(value => {
        return parseInt(value, 10)
        // return parseInt(value, 10) * 2.205 // remove KG from it and convert to lbs
      })

      if (!weight_end) {
        weight_end = 0
      }

      switch (height) {

        // return the combined points.
        case '147cm':
          return Calculator.calculate_points(weight_start, weight_end, [
            [119, 142],
            [143, 190],
            [191],
          ])
        case '150cm':
          return Calculator.calculate_points(weight_start, weight_end, [
            [124, 147],
            [148, 197],
            [198],
          ])
        case '152cm':
          return Calculator.calculate_points(weight_start, weight_end, [
            [128, 152],
            [153, 203],
            [204],
          ])
        case '155cm':
          return Calculator.calculate_points(weight_start, weight_end, [
            [132, 157],
            [158, 210],
            [211],
          ])
        case '157cm':
          return Calculator.calculate_points(weight_start, weight_end, [
            [136, 163],
            [164, 217],
            [218],
          ])
        case '160cm':
          return Calculator.calculate_points(weight_start, weight_end, [
            [141, 168],
            [169, 224],
            [225],
          ])
        case '163cm':
          return Calculator.calculate_points(weight_start, weight_end, [
            [145, 173],
            [174, 231],
            [232],
          ])
        case '165cm':
          return Calculator.calculate_points(weight_start, weight_end, [
            [150, 179],
            [180, 239],
            [240],
          ])
        case '168cm':
          return Calculator.calculate_points(weight_start, weight_end, [
            [155, 185],
            [186, 246],
            [247],
          ])
        case '170cm':
          return Calculator.calculate_points(weight_start, weight_end, [
            [159, 190],
            [191, 254],
            [255],
          ])
        case '173cm':
          return Calculator.calculate_points(weight_start, weight_end, [
            [164, 196],
            [197, 261],
            [262],
          ])
        case '175cm':
          return Calculator.calculate_points(weight_start, weight_end, [
            [169, 202],
            [203, 269],
            [270],
          ])
        case '178cm':
          return Calculator.calculate_points(weight_start, weight_end, [
            [174, 208],
            [209, 277],
            [278],
          ])
        case '180cm':
          return Calculator.calculate_points(weight_start, weight_end, [
            [179, 214],
            [215, 285],
            [286],
          ])
        case '183cm':
          return Calculator.calculate_points(weight_start, weight_end, [
            [184, 220],
            [221, 293],
            [294],
          ])
        case '185cm':
          return Calculator.calculate_points(weight_start, weight_end, [
            [189, 226],
            [227, 301],
            [302],
          ])
        case '188cm':
          return Calculator.calculate_points(weight_start, weight_end, [
            [194, 232],
            [233, 310],
            [311],
          ])
        case '191cm':
          return Calculator.calculate_points(weight_start, weight_end, [
            [200, 239],
            [240, 318],
            [319],
          ])
        case '193cm':
          return Calculator.calculate_points(weight_start, weight_end, [
            [205, 245],
            [246, 327],
            [328],
          ])
        default:
          return 0
      }

    },
  }

  constructor (values) {
    this.values = values
  }

  static calculate_points (weight_start, weight_end, conditions) {

    /**
     * Start to check the highest weight first.
     * go to mid
     * then try the lowest
     */
    console.warn(weight_start, weight_end, conditions)
    if (weight_start >= conditions[2][0]) {
      // 3 points
      console.warn('3 on height_weight ')
      return 3
    } else if (weight_start >= conditions[1][0] && weight_end <= conditions[1][1]) {
      // 2 points
      console.warn('2 on height_weight ')
      return 2
    } else if (weight_start >= conditions[0][0] && weight_end <= conditions[0][1]) {
      // 1 point
      console.warn('1 on height_weight ')
      return 1
    } else {
      console.warn('0 on height_weight ')
      return 0
    }
  }

  calculate () {
    let all_points = 0
    for (let [key, value] of Object.entries(this.values)) {

      if (key === 'height' || key === 'weight' ||
        typeof  Calculator.points[key] === 'undefined') {
        continue
      }
      console.warn(Calculator.points[key][value] + ' for ' + key + ' on ' +
        value)
      all_points += Calculator.points[key][value]
    }

    let height = this.values['height']
    let weight = this.values['weight']

    all_points += Calculator.points['height_weight'](height, weight)
    const total_question_points = 11
    const total_earnable = 10

    return Math.round((( all_points * 100) / total_question_points) / total_earnable)
  }

}