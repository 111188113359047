import React from 'react'
import logo from './assets/logo.png'
import aba from './assets/aba.png'
import circle from './assets/circle.png'
// import StartBtn from './assets/start-btn.png'
import pdf from './assets/pdf.png'
import FacebookLogin from 'react-facebook-login'
import axios from 'axios'
import { backend_url } from './helpers'
import { observer, inject } from 'mobx-react'
import PDFFile from './assets/risk-test-paper.pdf'
import MetaTags from 'react-meta-tags'

// axios.defaults.withCredentials = true
@inject('AppStore')
@observer
class StartPage extends React.Component {

  logout () {
    this.props.AppStore.removeUser()
  }

  continueAsGuest () {
    const {AppStore, onStart} = this.props

    onStart()
  }

  onLogin (prams) {
    // send ajax request to server.
    axios.post(backend_url('/auth/facebook-login'),
      {
        FB_SR: prams.signedRequest,
      }).then(response => response.data).then(json => {
      if (json.status === 'success' && json.user) {
        this.props.AppStore.setUser(json.user) // store the user in AppStore and mark logged-in
        this.props.onStart()
      } else {
        alert('We were unable to authenticate you, Please try again.')
      }
    })
  }

  render () {

    return <div>
      <div className="wrapper">
        <MetaTags>
          <title>Diabetes Assessment Tool</title>
          <meta name="description" content="Take the Type 2 Diabetes Risk Test."/>
          <meta property="og:title" content="Diabetes Assessment Tool"/>
          <meta property="og:image" content="https://getz.clients.thebrandcrew.com/static/media/logo.53a6df7f.png"/>
        </MetaTags>
      </div>
      <div className={'header'}>
        <div className={'header-wrap'}>
          <div><img className={'top-circle'} src={circle} alt=""/></div>
          <div><img className={'top-logo'} src={logo} alt=""/></div>
          <div>
            <a target={'_blank'} href="http://www.diabetes.org"><img className={'aba-logo'} src={aba} alt=""/></a>
          </div>
        </div>
      </div>
      <div className={'main-screen'}>
        <div className={'start-wrap'}>
          <div className={'action-btns'}>
            <div className={'facebook-login'}>
              {this.renderWelcomeOrLogin()}
            </div>
            <div className={'download-pdf-btn'}>
              <a href={PDFFile}>
                <img src={pdf} alt=""/>
              </a>


            </div>
            <div className="credits" style={{fontSize: 10, color: '#fff', marginTop: 10}}>
              Take the Type 2 Diabetes Risk Test. American Diabetes Association. 2018 [cited 18 October 2018].
              Available from: <a target='_blank' style={{color: '#fff'}}
                                 href="http://www.diabetes.org/are-you-at-risk/diabetes-risk-test/">http://www.diabetes.org/are-you-at-risk/diabetes-risk-test/</a>
            </div>
          </div>
        </div>

      </div>
    </div>

  }

  renderWelcomeOrLogin () {
    if (this.props.AppStore.user.api_token && this.props.AppStore.user.name) {
      return <div className={'welcome'}>
        <h3>Hi, {this.props.AppStore.user.name}</h3>
        <button onClick={this.props.onStart} className={'start-button'}>Start Test</button>

      </div>
    }

    return <div className="welcome">
      <h3>To Begin</h3>
      <FacebookLogin
        cookie={true}
        appId="2236788559930547"
        autoLoad={false}
        fields="name,email,picture"
        callback={this.onLogin.bind(this)}
        cssClass="facebook-login-button"
        icon="fa-facebook"
      />
      <span>or <a href="javascript:;" onClick={this.continueAsGuest.bind(this)}
                  className={'logout-button'}>Continue as Geuest</a></span>
    </div>

  }
}

export default StartPage