import { observable, action, intercept } from 'mobx'
import { create, persist } from 'mobx-persist'

/**
 * Global AppStore
 * Stores Everything that needs to be shared between components
 * @author Azeem Hassni <https://azeemhassni.com>
 */
class GlobalAppStore {
  /**
   * Information about the currently logged-in user
   * the values of object are returned from server.
   * @type {{}}
   */
  @persist('object') @observable user = {}

  /**
   * This property indicates that either
   * we have a logged-in user or not
   * @type {boolean}
   */
  @persist @observable isLoggedIn = false

  @action
  setUser (user) {
    this.user = user
    console.log('User has been set', this.user)
  }

  @action
  removeUser () {
    this.user = {}
  }
}

const hydrate = create({})

export const AppStore = new GlobalAppStore()
window.__Store = AppStore
// rehydrate the store from LocalStorage
hydrate('AppStore', AppStore).then(() => console.log(AppStore))

// just for development
intercept(AppStore, 'user', change => {
  console.log('user changed to ', change.newValue)
  return change
})